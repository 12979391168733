a,
a:link,
a:visited {
  text-decoration: none;
  color: #1f77b9;
}

.burger.burger-squeeze .burger-lines,
.burger.burger-squeeze .burger-lines:after,
.burger.burger-squeeze .burger-lines:before {
  background-color: #333;
}

.burger.burger-squeeze {
  font-size: 8px !important;
}

.noscroll {
  overflow: hidden;
}
